@import '../../mixins';
@import '../../palette';

.slider {
  padding: 0 50px 25px 50px;

  &__title {
    color: $brand-primary-colour;
    font-size: 30px;
    margin-block-end: 0;
  }
  &__desription {
    font-size: 24px;
  }
  &__items {
    @include flex-justify-align-wrap(space-between, none, wrap);
    width: auto;
  }

  &__row {
    padding-bottom: 24px;
  }
}

.slider-item {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  &__image {
    width: 100%;
    height: 325px;
    object-fit: cover;
  }

  &__title {
    font-size: 24px;
    margin-block-end: 0px;
    margin-block-start: 0px;
    min-height: 3.5rem;
    max-height: 3.5rem;
  }
  &__description {
    min-height: 80px;

    // @media(min-width: 1600px) {
    //   min-height: 95px;
    // }

    @media (max-width: 1360px) {
      min-height: 115px;
    }

    @media(max-width: 976px) {
      min-height: 190px;
    }

    @media(max-width: 899px) {
      min-height: 152px;
    }

    &-longer {
      min-height: 115px;

      // @media(min-width: 1600px) {
      //   min-height: 95px;
      // }

      @media(max-width: 1300px) {
        min-height: 190px;
      }

      @media(max-width: 1020px) {
        min-height: 247px;
      }

      @media(max-width: 899px) {
        min-height: 152px;
      }
    } 
  }

  &__link {
    min-height: 30px;
  }

  &__info {
    padding: 30px 30px 10px 30px;
    background: $ui-background;
    flex-grow: 1;
    position: relative;

    &-lob {
      height: 26px;
      width: 97px;
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%);
      opacity: 0.7;
      position: absolute;
      top: -12px;
      right: -2rem;
      margin-right: 2rem;
      margin-bottom: 2.3rem;
    }
      
    a {
      text-align: right;
      text-decoration: none;
      color: $brand-primary-colour;
      font-weight: 500;
      font-size: 20px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      min-height: 30px;
      
      & i.fa-chevron-right{
        font-size: 1rem;
        padding: 0.8rem 0.2rem; 
      } 
    }
  }  
}

@media (max-width: 767px) {
  .slider {
    padding: 0;

    &__info {
      padding: 40px 30px;
    }

    &__description {
      font-size: 16px;
      font-weight: 400;
    }
  }
}