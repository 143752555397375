@import '../../mixins';
@import '../../palette';

.hero-split {
    @include flex-justify-align-wrap(center, center, nowrap);
    width: 100%;
    min-height: 400px;
    position: relative;

    &__title {
      margin: 1rem 0;
      font-size: 30px;
      color: $brand-primary-colour;

      &-big-title {
        font-size: 100px;
        color: $brand-primary-colour;

        @media (max-width: 1350px) {
          font-size: 60px;
        }
      }
    }

    &__description {
      margin-bottom: 60px;
      color: $text-primary-colour;

      &-big-desc {
        font-size: 30px;
        color: $text-primary-colour;
        
        @media (max-width: 1350px) {
          font-size: 24px;
        }
      }
    }
   
    &__info {
      margin-left: 92px;
      margin-right: 74px;

      @media(max-width: 767px) {
        margin: 0;
      }

      @media (min-width: 1800px) {
         max-width: 500px;
         margin-left: 220px;
      }

      &-left {
        margin-left: 74px;
        margin-right: 92px;   
        
        & h3 {
          font-size: 30px;
          color: $brand-primary-colour;
        }
      }
      
    }

    &__image {
      position: relative;

      & img {
        width: 100%;
        height: 100%;
      }
    }

    &__button {
      text-align: center;
      text-decoration: none;
      padding: 15px 45px;
      color: #2F7000;
      display: inline-block;
      border: 2px solid #2F7000;
      margin-right: 10px;

      &.solid {
        background: $brand-primary-colour;
        color: $text-inverse-colour;
        border: none;
        padding: 15px 53px;
      }
    }

    &__headline {
      min-height: 600px;
      color: $text-inverse-colour;
      text-align: center;
      @include flex-justify-align-wrap(center, center, nowrap);
      flex-direction: column;
      background-size: cover;
      background-repeat: no-repeat;

      @media (min-width: 1400px) {
        max-width: 1300px;
        margin: auto;
      }
      
      & h1 {
        margin-block-end: 0;
        font-size: 100px;
        font-weight: 900;
        text-shadow: 0px 3px 56px #00000055;
      }

      & p {
        width: 60%;
        font-size: 16px;
        text-shadow: 0px 3px 18px #0000006C;
      }
      &__button {
        text-align: center;
        text-decoration: none;
        padding: 17px 50px;
        color: $text-inverse-colour;
        border: 2px solid #8BC73B;
        margin-right: 10px;
        &.solid {
          background: $ui-quarternary-colour;
          color: $brand-primary-colour;
          border: none;
        }   
      }
    }

    
}

@media (max-width: 767px) {
  .hero-split {
    flex-wrap: wrap;
    &__info,
    &__image {
      width: 100%;
    }

    &__info {
      padding: 15px 0;
    }

    &__button,
    &__button.solid {
      padding: 10px 30px;
    }

    &__headline {
      width: 100%;
      & h1 {
        font-size: 40px;
      }
    }
  }
}