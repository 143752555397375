@import '../../mixins';

.images {
    
    &__items {
        @include flex-justify-align-wrap(center, center, wrap);
        width: 100%;
        gap: 20px;
    }

    &__item {
        
        & img {
            width: 100%;
        }
    }
}