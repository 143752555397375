@import '../../../../palette';

.newsletter {
  width: 100%;

  .server{
    text-align: right;
  }

  &__form {
    display: flex;
    width: 100%;
    display: flex;
  }

  & input[type=email] {
    padding: 0.8rem;
    border-radius: 0;
    border: 1px solid black;
    border-right: none;
    border-right: 0;
    font-size: 16px;
    width: 88%;
  }

  & input[type=email]:focus {
    outline: none;
  }

  & input[type=email].disabled-input {
    opacity: 0.5;
  }
  
  & a {
    padding: 1rem;
    background: white;
    border: 1px solid black;
    border-left: none;
    font-size: 16px;
    color: $brand-primary-colour;
  } 
  
  .disabled-button {
    color: $text-secondary-colour;
    opacity: 0.5;
  }
}
