@import '../../../palette';


.footer {
  padding: 25px 50px;

  @media (max-width: 572px) {
    padding: 20px 0;
  }


  &__top,
  &__bottom {
    display: flex;
    flex-wrap: wrap;
  }

  &__top ul {
    list-style-type: none;
    padding-inline-start: 0px;
  }

  &__logo {
    width: 35%;

    & h3 {
      font-size: 24px;
      margin-bottom: 45px;
    }

    & h5 {
      font-size: 12px;
      margin-bottom: 25px;
    }

    & img {
      width: 300px;
      height: auto;
    }
  }

  &__solutions,
  &__industries,
  &__company {
    width: 13%;
    font-size: 18px;
    & a {
      color: $text-primary-colour;
      text-decoration: none;
    }
    & li {
      margin-bottom: 8px;
    }
  }

  &__bottom {
    justify-content: space-between;
    align-items: center;
  }
  
  &__newsletter {
    width: 30%;
  }
}

.social__icons {
  padding-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  & .icon {
    & div {
      background: #707070;
      width: 15px;
      height: 15px;
      padding: 10px;
      border-radius: 50%;
    }
    
    & img {
      width: 100%;
      height: 100%;
    }    
  }
}

.social__icons-mobile {
  padding: 1.4rem 0;
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  & .icon {
    & div {
      background: #707070;
      width: 20px;
      height: 20px;
      padding: 0.6rem;
      border-radius: 50%;
    }
    
    & img {
      width: 100%;
      height: 100%;
    }    
  }
}

@media (max-width: 1030px) {
    footer {
        padding: 38px 25px;
    }
    .footer {
        &__logo,
        &__solutions,
        &__industries,
        &__company,
        &__newsletter {
            width: 100%;
            max-width: 400px;
        }

        &__logo img {
            width: 300px;
        }
    }
}