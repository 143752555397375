@import '../../mixins';
@import '../../palette';

.icons {
    @include flex-justify-align-wrap(space-between, center, nowrap);
    background: $ui-background;
    padding: 10px;
    width: 99%;
    
    & h3 {
        width: 25%;
        text-align: center;
        font-size: 30px;
        color: $brand-primary-colour;
    }

    &__items {
        @include flex-justify-align-wrap(space-between, baseline, wrap);
        width: 74%;
    }

    &__item {
        text-align: center;
        padding: 10px;
        & img {
            width: 100%;
        }
        & h4 {
            width: 100%;
            font-size: 24px;
        }
    }

}
