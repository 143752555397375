// Brand

$brand-primary-colour: #007000;
$brand-primary-dark-colour: #245838;
$brand-secondary-colour: #1D7271;
$brand-accent-colour: #D10074;

// UI
$ui-primary-colour: #555555;
$ui-border: #CCCCCC;
$ui-background: #F1F1F1;
$ui-quarternary-colour: #FFFFFF;
$ui-disabled-colour: #EFEFEF;
$ui-error-colour: #B31E2B;
$ui-success-colour: #007000;

// Text
$text-primary-colour: #000000;
$text-secondary-colour: #212121;
$text-secondary-colour: #9C9C9C;
$text-inverse-colour: #FFFFFF;
$text-error-colour: #B31E2B;
$text-success-colour: #007000;

$technology: #9B26B6;
$facilities: #0086BF;
$furniture: #1D4F91;

//Borders
$border-color: #707070;
