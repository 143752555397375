@import '../../palette';

.multi-topic-with-scroller {
  padding: 50px 0;
  position: relative;
  
  .hero {
    display: flex;
    flex-direction: column;
    &-image {
      position: relative;

      & img {
        width: 100%;
        height: 616px;
      }

      &-lob {
        height: 26px;
        width: 97px;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%);
        opacity: 0.7;
        position: absolute;
        bottom: -8px;
        right: -2rem;
        margin-right: 2rem;
      }
    }

    &-title{
      & h2 {
        color: $ui-success-colour;
      }
    }


  }

  .scroller-cards {
    padding: 0 3rem;
    height: 750px;
    overflow-y: scroll;

    @media (max-width: 899px) {
      padding: 0;
    }
  }

  .card {
    display: flex;
    gap: 2rem;
    padding: 1rem 2rem;
    border-bottom: 1px solid $border-color;

    & img {
      border-radius: 50%;
      width: 120px;
      height: 120px;
    }

    &-cta {
      display: flex;
      justify-content: flex-end;

      & a {
        text-decoration: none;
        color: $ui-success-colour;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }

  .card:first-child {
    padding: 0rem 2rem 1rem 2rem;

    @media (max-width: 899px) {
      padding: 2rem 0;
    }
  }

  .card:last-child {
    border-bottom: none;
  }
};