@import '../../palette';

.article-content-page {
   @media (max-width: 776px) {
      padding: 2.6rem 0;
    }
  // a {
  //   color: $ui-success-colour !important;
  // }

  // & figure {
  //   margin: 16px 0;

  //   & img {
  //     width: 100%;
  //   }
  // }

}