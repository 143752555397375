@import '../../../palette';

.breadcrumbs {
    padding: 2rem 50px;


    & a {
        text-decoration: none;
        color: $text-primary-colour;
        margin-right: 5px;

        &.active {
            color: $brand-primary-colour;
        }
    }
}