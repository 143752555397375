@import '../../../mixins';
@import '../../../palette';

nav {
  @include flex-justify-align-wrap(space-between, baseline, nowrap);
  padding: 5px 50px;
  position: relative;

  ul {
    list-style-type: none;
    padding-inline-start: 0px;
  }  

  @media (max-width: 776px) {
    display: none;
  }
}

.nav__item {
  display: inline-block;
  font-size: 18px;

  & span {
    cursor: pointer;
    padding: 20px 20px;
    margin: 0 1rem;
  }
  
  & i {
    padding: 0 10px;
  }
}
.buttons {
  margin-right: 24px;
}

.nav__link,
.buttons a {
  text-decoration: none;
  color: $text-primary-colour;
  font-size: 18px;
  padding: 1.2rem 2rem; //20px 20px;
  margin: 0 1rem;
}

.nav__link:first-child {
  margin-left: -5px;
}

