@import '../../mixins';
@import '../../palette';

.split {
  @include flex-justify-align-wrap(center, none, wrap);
  gap: 20px;
  padding: 50px;
  
  &__item {
    color: $text-primary-colour;
    display: flex;
    flex-direction: column;

    & img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    }

    &-lob {
      height: 20px;
      width: 100px;
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%);
      opacity: 0.7;
      position: absolute;
      top: -10px;
      right: 0;
    }

    &-link {
      color: $brand-primary-colour;
      text-decoration: none;
      font-weight: 500;
      font-size: 20px;
    

      &-right {
        color: $brand-primary-colour;
        text-decoration: none;
        font-weight: 500;
        font-size: 20px;
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }
    }

    &-title {
      @include flex-justify-align-wrap(space-between, baseline, nowrap);

      & h3 {
        font-size: 24px;
        margin-block-end: 0;
      }
    }

    &-info {
      margin-top: 0;
      background: $ui-quarternary-colour;
      padding: 8px 50px 10px 33px;
      position: relative;

      & p {
        min-height: 76px;
      }
    }
  }
}

@media (max-width: 767px) {
  .split {
    padding: 25px 0;
    flex-wrap: wrap;
  }
}