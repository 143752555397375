@import './palette';

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section:nth-of-type(1n) {
  background: $ui-quarternary-colour;
  .article__info,
  .article-full-width,
  .split-info,
  .slider-item__info,
  .feature-list-icons-inner-container {
     background: $ui-background;
    height: 100%;
  }
}

section:nth-of-type(2n) {
   background: $ui-background;
  .article__info,
  .article-full-width,
  .split-info,
  .slider-item__info,
  .feature-list-icons-inner-container {
    background: $ui-quarternary-colour;
    height: 100%;
  }
}

@media (max-width: 767px) {
  body {
    margin: 0;
  }
}
