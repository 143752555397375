@import '../../../mixins';
@import '../../../palette';

header {
  @media (max-width: 776px) {
    display: none;
  }
  padding: 16px 0;
  margin: 0 50px;
}

.mobile__header {
  @media (min-width: 777px) {
    display: none;
  }

  @include flex-justify-align-wrap(space-between, center, nowrap);
  width: 100%;
  padding: 8px 0;
  background: $ui-background;
  position: fixed;
  top: 0;
  z-index: 1000;
  
  &-bars, &-bars-right {
    font-size: 27px;
    width: 25px;
    height: 26px;
    color: $brand-primary-colour;
 
  }

  &-bars {
    flex: 1 1 0;
    i {
      margin-left: 15px;
    }
  }

  &-bars-right {
    padding: 6.5px 0;
  }
 
  &-logo {
    display: flex; 
    flex: 1 1 0;
    padding: 5px 0;
      & img {
          width: 106px;
      }
  }

  &-contact, &-bars-right {
    flex: 1 1 0;
    display: flex;
    justify-content: flex-end;

    a {
      cursor: pointer;
      padding: 10px 15px;
      background: $brand-primary-colour;
      border:none ;
      color: $text-inverse-colour;
      text-decoration: none;
    }

    i {
      margin-right: 12px;
    }
  }

}

.logo {
    @include flex-justify-align-wrap(space-between, none, nowrap);
    width: 100%;

    &__image {
        width: 250px;
        height: auto;  
    }
}

.top {
    @include flex-justify-align-wrap(flex-end, baseline, nowrap);
    width: 70%;

    & ul {
      padding: 10px;
      margin-left: 15px;
      font-size: 14px;
      display: flex;
      gap: 20px;
      align-items: baseline;

      & a {
        text-decoration: none;
      }

      & li:first-child {
        list-style: none;

        & a {
          color: #57A5A3;
          display: flex;
          gap: 5px;
          justify-content: center;
          align-items: baseline;
        }        
      }

      & li:nth-child(2){
        font-weight: bold;

        & a {
          color: $text-primary-colour;
        }
      }

      & li:nth-child(3) {
        & a {
          color: $text-primary-colour;
        }
      }
    }

    &-lang {  
      cursor: pointer;
    }

    & form {
      display: flex;
      justify-content: center;
    }
    & input[type=search] {
      padding: 10px 18px;
      width: 250px;
      border: 1px solid $text-primary-colour;
    }

    & button {
      cursor: pointer;
      padding: 8px 21px;
      background: $brand-primary-colour;
      border:none ;
      color: $text-inverse-colour;

      & img {
        width: 20px;
        height: 20px;
      }
    } 
}

.buttons {
  & a {
    margin-right: 6px;
    font-size: 20px;
    font-weight: 500;
    background: $brand-primary-colour;
    color: $text-inverse-colour;
    padding: 11px 36px;
  }
}