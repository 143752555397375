@import '../../../palette';

.article {
  display: flex;
  flex-direction: column;

  &__image {
    width: 100%;
    height: 325px;

    @media (max-width: 572px) {
      height: 259px;
    }
    
    object-fit: cover;
  }

  &__info {
    margin-top: 0;
    padding: 15px;
    background: $ui-quarternary-colour;
    position: relative;

    & h4 {
      margin-block-start: 1px;
      font-size: 24px;
      margin-bottom: 0.6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      min-height: 3rem;
      max-height: 4rem;
    }

    &-text {
      min-height: 5rem;
      max-height: 5rem;
      margin: 1rem 0;
      & p {
        & span {
          padding: 0;
          margin:0;
        }
      }
    }

    &-cta {
      min-height: 1.6rem;
    }

    &-tags {
      margin-top: 20px;
      min-height: 160px;

      .tag {
        display: inline-block;
        padding: 10px 15px;
        background-color: $ui-border;
        border-radius: 18px;
        margin: 7px 10px 7px 0;  
      }

      .dots {
        font-size: 30px;
        font-weight: 900;
        color: $border-color;
        font-size: 30px;
        display: inline-block;
        margin: 0 20px;
      }
    }
  }

  &__lob {
    height: 20px;
    width: 100px;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%);
    opacity: 0.7;
    position: absolute;
    top: -10px;
    right: 0px;

    & p {
      padding: 0 20px;
      margin: 0;
    }
  }

  &__link {
    color: $brand-primary-colour;
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    width: 100%;
    display: flex;
    width: 100%;
    justify-content: flex-end;

    & i.fa-chevron-right{
      font-size: 1rem;
      padding: 0.8rem 0.2rem; 
    } 
  }
}

@media (max-width: 572px) {
  .article {
      width: 100%;
  }
}