@import '../../../palette';

.right-mobile {
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
}

.article-full-width {
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  &__info {
    display: flex;
    width: 50%;

    @media (max-width: 767px){
      width: 100%;
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem 3rem;

      @media (max-width: 767px){
        padding: 1rem;
      }
    }

    & h4 {
      margin-block-start: 1px;
      font-size: 24px;
      margin: 1rem 0;
    }

    &-description {
      min-height: 95px;
    }
  }

  &__image {
    width: 50%;
    position: relative;
    max-height: 350px;
    min-height: 325px;

    @media (max-width: 767px){
      width: 100%;
      min-width: 240px;
    }

    & img {
      display:none;
      
      @media (max-width: 767px){
        display: flex;
        width: 100%;
        height: 100%;
       
      }
    }  

    &-lob-left {
      height: 20px;
      width: 100px;
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%);
      opacity: 0.7;
      position: absolute;
      bottom: -10px;
      right: 0;
    }

    &-lob-right {
      height: 20px;
      width: 100px;
      clip-path: polygon(0 0, 100% 0, 81% 100%, 0% 100%);;
      opacity: 0.7;
      position: absolute;
      bottom: -10px;
    }
  }

  &__link {
    display: flex;
    justify-content: flex-end;
    text-decoration: none;
    padding: 13px 0;
    color: $brand-primary-colour;
    font-weight: bold;
    font-size: 20px;

    a {
      text-align: right;
    }

    & i.fa-chevron-right{
      font-size: 1rem;
      padding: 0.8rem 0.2rem; 
    } 
  }
}
