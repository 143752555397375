@import '../../palette';


.feature-list {
  position: relative;

  & h3 {
    font-size: 30px;
    color: $brand-primary-colour;
    margin-block-end: 0;
    margin-top: 3rem;
  }

  &__bullets {
    display:contents;

    &-item {
      padding: 1rem;
    }

    &-title {
      margin: 0;
    }

    &-description {
      margin-top: 8px;
    }

  }
}