@import '../../mixins';
@import '../../palette';


.search-navigation {
  padding: 2rem 0;


  // @media (min-width: 1400px) {
  //   max-width: 1300px;
  //   margin: auto;
  // }

  // @media (max-width: 767px) {
  //   padding: 0;
  // }

  &-content {
    border: 1px solid $border-color;
    padding: 1rem;

    @media (max-width: 767px) {
      border: none;
      max-width: 350px;
      margin: auto;
    }
    
    & h2 {
      color: $brand-primary-colour;
      padding: 1rem;
      margin: 0;
    }

    &-filters {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      margin-bottom: 1rem; 
        
      @media (max-width: 767px) {   
        flex-wrap: wrap;
      }
    }

    &-filter {
      display: flex;
      flex-direction: column;
      justify-content: center; 
      align-items: center;
      padding: 0 1rem;
      border: 1px solid $border-color;
      font-size: 1.2rem;
      position: relative;
      cursor: pointer;
      width: 120px;

      @media (max-width: 767px) { 
        padding: 0.6rem 1rem;
        font-size: 1rem;
      
        text-align: center;
        margin: 5px;
        flex: 1;
      }

      &-lob {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 28px;

        &-inside {  
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%);
        height: 8px;
        top: 0;
        }
      }
    }

    &-filter.active {
      background-color: $ui-background;
    }

    &-sortBy {
      width: 30%;
      position: relative;
      cursor: pointer;

      @media (max-width: 767px) {
        width: 100%;
        margin: 30px 5px;
      }

      &-dropdown {
        display: flex;
        justify-content: space-between;
        border: 1px solid $border-color;
        padding: 0.8rem;
      

        & i {
          color: $brand-primary-colour;
          font-size: 16px;
        }
      }  

      & ul {
        position: absolute;
        width: 100%;
        background: $ui-quarternary-colour;
        margin: 0;
        list-style-type: none;
        padding: 0;
        border: 1px solid $border-color;

        & li {
          padding: 0.875rem;          
        }

        & li:hover, li.active {
          background-color: $ui-background;
        }
      }
    }
  }


}