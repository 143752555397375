@import '../../mixins';
@import '../../palette';

.process-ordered-list {
	padding: 50px;
	

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
  .list-header {
		&__title {
			margin: 0;
			font-size: 30px;
			color: $brand-primary-colour;
			margin-block-end: 0;
		}

		&__description {
			font-size: 16px;
		}
	}

	.single-card {
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 2rem;
		padding: 1rem 1rem 1rem 5rem;

		@media (max-width: 767px){
			flex-direction: column;
		}

		&__image {
			display: flex;
			width: 30%;
			position: relative;

			@media (max-width: 767px){
				width: 100%;
				height: 100%;
				min-height: 306px;
				max-height: 306px;
			}
			&-lob {
				height: 20px;
				width: 100px;
				clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%);
				opacity: 0.7;
				position: absolute;
				bottom: -10px;
				right: 0;
				background: $brand-primary-colour;
			}
		}

		&__info {
			display: flex;
			flex-direction: column;
			width: 70%;

			@media (max-width: 767px){
				width: 100%;
			}

			&-title {
				margin: 0;
				font-size: 24px;
				justify-content: flex-start;
			}

			&-description {
				padding-right: 3rem;

				@media (max-width: 767px){
					padding-right: 0;
				}
			}

			&-link {
				display: flex;
				justify-content: flex-end;
				font-size: 20px;
				font-weight: bold;
				color: $brand-primary-colour;
				text-decoration: none;


				& i.fa-chevron-right{
					font-size: 1rem;
					padding: 0.8rem 0.2rem; 
				} 
			}
		}
	}
}