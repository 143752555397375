@import '../../../../palette';

.dropdown {
  display: flex;
  position: absolute;
  left: 4rem;
  right: 6rem;
  top: 3.8rem;
  z-index: 1;

  @media (min-width: 1700px) {
    left: 13rem;
  }

  &__categories {
    flex-grow: 0;
    min-width: 240px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background: $ui-quarternary-colour;

    &-lob {
      background: $ui-background;

      &-inside {  
      background: $brand-primary-colour;
      clip-path: polygon(0 0, 92% 0, 100% 100%, 0% 100%);
      height: 8px;
      top: 0;
      }
    }

    & li {
      & a {
        padding: 1rem 0 1rem 2.6rem;
        text-align: left;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        text-decoration: none;
        color: $text-primary-colour;
        
        & i.fa-chevron-right {
          width: 5px;
          height: 8px;
          margin-right: 1rem;
        }
      }
    }
  }

  &__subcategories {
    // width: 75%;
    // max-width: 62%;
    min-width: 240px;
    padding: 10px;
    background-color: $ui-background;

    & li {
      padding: 1rem 2rem;
      text-align: left;
    }

    & a {
      display: block;
      color: $text-primary-colour;
      text-decoration: none;
    }

  }

  &__article,
  &__articles-full {
    background: $ui-background;
  }

  &__article {
    // width: 44%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;

    & a {
      color: $brand-primary-colour;
       margin: 0 2rem;
      font-weight: 500;
      text-decoration: none;
      font-size: 1.4rem;
    }

    &-single {
      max-width: 400px;

      & a {
        margin: 0;
      }
    }
  }

  &__articles-full {
    width: inherit;
    display: flex;
    padding: 10px;
    justify-content: center;
    gap: 15px;
  }
  .active {
    background: $ui-background;
  }

}