@import '../../palette';


.feature-list-icons {

  padding: 25px 0 50px ;

  & h3 {
    font-size: 30px;
    color: $brand-primary-colour;
    margin-block-end: 0;
    margin-top: 3rem;
  }

    &__item {
      padding: 1rem;
      display: flex;
      gap:2rem;

      @media (max-width: 572px) {
        flex-direction:  column;
        padding: 0;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
    }

    &__title {
      margin: 0;
      font-size: 30px;
      color: $brand-primary-colour;
      min-height: 70px;

      @media (max-width: 572px) {
        font-size: 24px;
      }
    }

    &__description {
      margin-top: 8px;
      
      & ul {
        padding: 0 1rem;
      }
    }
    
    &__icon {
      background: $ui-quarternary-colour;
      width: 70px;
      height: 70px;
      padding: 18px;
      border-radius: 50%;
      border: 1px solid #8cc800;
      text-align: center;
    
    }
  
}