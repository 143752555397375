@import '../../mixins';
@import '../../palette';

.cards {
  padding: 25px 50px;
  position: relative;

  &__title {
    font-size: 30px;
    margin-block-end: 0;
    color: $brand-primary-colour;
    display: flex;
    justify-content: center
  }

  &__description {
    font-size: 24px;
    color: $text-primary-colour;
    font-weight: 500;
    text-align: center;
  }

  &__items {
    @include flex-justify-align-wrap(space-between, flex-start, wrap);
    gap: 5px;
  }
}


@media (max-width: 767px) {
  .cards {
    &__description {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 30px;
    }

  }
}
