@import '../../palette';

.logo-call-out {

  &__header {

    padding: 1rem 0;

    & h1 {
      color: $ui-success-colour;
      font-size: 32px;
      margin-bottom: 0; 
    }

    .desc {
      font-size: 24px;
      padding: 0;
    }
  }

  &__icons {
    padding: 1rem 0;

    &-icon {
      @media (max-width: 899px) {
        padding: 1rem;
      }
    }
  }

  &__cta {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;

    & a {
      text-decoration: none;
      color: $ui-success-colour;
      font-weight: bold;
      font-size: 20px;
    }
  }

  &__brands {
    padding: 2rem 1rem 0;

    &-brand {
      @media (max-width: 899px) {
        padding: 1rem;
      }
    }
  }
}