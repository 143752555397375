.clip-path {
  &__left {
      position: absolute;
      height: 53px;
      width: 194px;
      opacity: 0.8;
      clip-path: polygon(0 0, 100% 0, 81% 100%, 0% 100%);
      left: 0;
      top: -25px;
  }

  &__right {
      position: absolute;
      height: 53px;
      width: 194px;
      opacity: 0.8;
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%);
      right: 0;
      bottom: -20px;
  }
}

@media (max-width: 572px) {
  .clip-path {
    &__left,
    &__right {
      height: 25px;
      width: 100px;
    }

    &__left{
      top: -12px;
    }

    &__right{
      bottom: -12px;
    }
  }
}