@import '../../../../mixins';
@import '../../../../palette';


.mobile {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  min-height: 100%;
  overflow-y: scroll;
  &__top {
    @include flex-justify-align-wrap(space-between, center, nowrap);
    width: 100%;
    padding: 5px 0;
    background: $ui-background;
    &-bars {
        font-size: 27px;
        color: $brand-primary-colour;
        margin: 0 15px;
    }
    &-logo {
        & img {
            width: 130px;
        }
        
        padding: 5px 0;
    }
    &-contact a {
        cursor: pointer;
        padding: 8px 21px;
        background: $brand-primary-colour;
        border:none ;
        color: $text-inverse-colour;
        text-decoration: none;
    }
  }

  &__menu, 
  &__submenu {
    position: absolute;
    background: $brand-primary-colour;
    width: 100%;
    min-height: 100%;
    z-index: 10;
    top: 50px;
    &-wrapper,
    &-lob,
    &-other,
    &-locale {
      padding: 20px 0;
    }
    &-chat {
      & a {
        text-decoration: none;
        color: $text-inverse-colour;
      }
    }
    &-main,
    &-lob
      {
      & ul {
        padding-inline-start: 0;
      }
      border-bottom: 1px solid $ui-quarternary-colour;
    }

    &-lob-ul {
      border: none;
      & ul {
        padding-inline-start: 0;
      }
    }
    
    &-wrapper {
      padding: 16px 37px;

      & form {
        display: flex;
      }
      & input[type=search] {
        padding: 12px 18px;
        width: 90%;
        border: none;
        border-radius: 21px 0 0 21px;
      }
      
      & button {
        cursor: pointer;
        padding: 9px;
        background: $ui-quarternary-colour;
        border: none;
    
        color: $brand-primary-colour;
        border-radius: 0 21px 21px 0;    
      } 

      & h2 {
        padding: 20px 0;
        margin: 0;
        color: $text-inverse-colour;
      }
    }

    &-other {
        font-weight: 100;
        font-size: 16px;
        color: $text-inverse-colour;
        border-bottom: 1px solid $ui-quarternary-colour;
        display: flex;
        flex-direction: column;

        & a {
          text-decoration: none;
          color: $text-inverse-colour;
          padding: 0.4rem 0;
        }
    }

    &-locale {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      color: $text-inverse-colour;
      font-size: 20px;

      & div {
        font-size: 14px;
        border-bottom: 0;
        & i {
          margin-right: 5px;
        }
      }
    }
  }

  &__link,
  &__link a {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: $text-inverse-colour;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
  }

  &__link {
    margin-bottom: 30px;
  }

  &__submenu {
  &-h2-top {
    border-bottom: 1px solid $ui-quarternary-colour;
  }
  }
}

.language {
  font-size: 20px;
  color: $text-inverse-colour;
  text-decoration: underline;
  font-weight: lighter;
  &.selected {
    text-decoration: none;
    font-weight: bold;
  }
}

.languages {
  border-bottom: 0;
}
