.card {
  padding: 10px;
  text-align: left;
  height: auto;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__description {
    text-align: center;
  }
    & h4 {
      font-size: 24px;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    &__image {
      max-width: 120px;
      max-height: 120px;
    }
}

