@import '../../mixins';
@import '../../palette';

.carousel {
  border-top: 1px solid $ui-border;
  margin-top: 20px;
  width: 100%;

  &__slider {
    min-height: 360px;
  }
    
  &__items {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__items > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
  }

  &__item {
    margin-top: 30px;
    width: 80%;
    & img {
      width: 40%;
    }
  }

  &__navigation {
    @include flex-justify-align-wrap(space-around, center, nowrap);
    margin-top: 2rem;
  }

 &__button {
    border: none;
    color: $brand-primary-colour;
    background: $ui-quarternary-colour;
    font-size: 25px;
    padding: 15px;
    border-radius: 0;
    text-align: center;

    &.first {
        transform: rotate(180deg)
    }
 }

  &__back-button,
  &__next-button {
    background: $ui-quarternary-colour;
    border: none;
  }

  &__dots {
    @include flex-justify-align-wrap(space-between, center, nowrap);
  }

  &__dot {
    background: $ui-quarternary-colour;
    width: 15px;
    height: 15px;
    border: 1px solid $ui-border;
    border-radius: 50%;
    margin: 0 10px;

    &-group {
      display: flex;
    }

    &--selected {
        background: $brand-primary-colour; 
        border: 1px solid $brand-primary-colour;
    }
  }
}

@media (min-width: 768px) {
 .carousel {
    &__inner {
      &-slide {
        display: flex;
        justify-content: center;
      }
    }

    &__slide {
      &--hidden,
      &--visible {
        padding-bottom: 8% !important;
      }
    }
 }
}

@media (max-width: 576px) {
  .carousel {
    &__slide {
      &--hidden,
      &--visible {
        padding-bottom: 14% !important;
      }
    }
  }
}
