@import '../../mixins';
@import '../../palette';

.articles {
  padding: 50px;

  @media (max-width: 572px) {
    padding: 20px 0;
  }

  &__header {
    padding: 1rem 0;
  }

  &__title {
    margin: 0;
    font-size: 30px;
    color: $brand-primary-colour;
    margin-block-end: 0;
  }

  &__description {
    font-size: 24px;
    font-weight: 500;
  }

  &__link {
    text-align: right;
    margin-top: 20px;
    a {
      color: $brand-primary-colour;
      text-decoration: none;
      font-weight: 500;
      font-size: 20px;       
    }
  }

  


  // &__items {
  //   @include flex-justify-align-wrap(center, inherit, nowrap);
  //   gap: 20px;
  //   padding: 0 3rem;
  // }
}

