@import '../../mixins';
@import '../../palette';

.bullets {
  display: flex;
  flex-wrap: nowrap;
  min-height: 500px;
  padding: 2rem;
  position: relative;

  &__info {
    padding: 0 3%;
    background-size: contain;
    text-align: center;
    @include flex-justify-align-wrap(center, center, nowrap);
    flex-direction: column;
    align-content: center;

    & h3 {
      font-size: 100px;
      margin-block-end: 0;
      color: $brand-primary-colour;
      text-align: left;
    }

    & p {
      font-weight: 500;
      color: $text-primary-colour;
      text-align: left;
      font-size: 30px;
    }
  }

  &__points {
    padding: 0 3%;
    border-left: 1px solid $ui-border;
    @include flex-justify-align-wrap(center, center, wrap);
    flex-direction: column;
  }
}

.item {
  @include flex-justify-align-wrap(flex-start, flex-start, nowrap);
  gap: 20px;
  width: 100%;
  padding: 2rem 0;

  &__icon div {
    background: $ui-quarternary-colour;
    width: 70px;
    height: 70px;
    padding: 18px;
    border-radius: 50%;
    border: 1px solid #8cc800;
    text-align: center;
    & img {
      width: 100%;
      height: 100%;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & h4 {
      margin: 0%;
      font-size: 30px;
      margin-block-end: 0;
      color: $brand-primary-colour;
    }
  }
}

@media (max-width: 572px) {
  .bullets {
    flex-wrap: wrap;
    padding: 3% 20px;
    &__info,
    &__points {
      width: 100%;     
    }

    &__info {
      & h3 {
        font-size: 30px;
      }
      & p {
        font-size: 16px;
        font-weight: 400;
      }
    }

    &__points {
      border-left: none;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    .item {
      flex-direction: column;
      width: 47%;

      &__description {
        & h4 {
          font-size: 24px;
        }
      }
    }
  }

  // .item {
  //   flex-direction: column;
  //   justify-content: flex-start;
  //   align-items: flex-start;
  //   gap: 20px;
  //   width: 47%;
  //   &__description {
  //     & h4 {
  //       font-size: 24px;
  //       margin-block-start: 0;            
  //     }
  //   }
  // }
}