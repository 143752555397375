@import '../../mixins';

.hero-material {
    @include flex-justify-align-wrap(center, none, nowrap);
    padding: 6px;

    &__image {
        width: 35%;

        & img {
            width: 100%;
        }
    }

    &__info {
        width: 50%;
    }
}